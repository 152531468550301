import React, { Suspense, useEffect, useState, useRef, useMemo } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import * as THREE from "three";
import "./App.css";
import ReactGA from "react-ga4";
import LoadingScreen from "./LoadingScreen";
import TextOverlay from "./TextOverlay";

function NoisyRing({ isSmallScreen }) {
  const ringRef = useRef();
  const particlesRef = useRef();
  const mouseRef = useRef({ x: 0, y: 0 });
  const rotationRef = useRef({ x: 0, y: 0 });
  const isMouseInScreen = useRef(true);

  const particlesGeometry = useMemo(() => {
    const geometry = new THREE.BufferGeometry();
    const particleCount = isSmallScreen ? 50000 : 100000; // Reduce particles for small screens
    const positions = new Float32Array(particleCount * 3);

    for (let i = 0; i < particleCount; i++) {
      const theta = Math.random() * Math.PI * 2;
      const r = THREE.MathUtils.randFloat(0.75, isSmallScreen ? 2 : 3); // Smaller radius for small screens
      positions[i * 3] = r * Math.cos(theta);
      positions[i * 3 + 1] = r * Math.sin(theta);
      positions[i * 3 + 2] = THREE.MathUtils.randFloatSpread(0.5);
    }

    geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));
    return geometry;
  }, [isSmallScreen]);

  useEffect(() => {
    const handleMouseMove = (event) => {
      isMouseInScreen.current = true;
      mouseRef.current = {
        x: (event.clientX / window.innerWidth) * 2 - 1,
        y: -(event.clientY / window.innerHeight) * 2 + 1,
      };
    };

    const handleMouseLeave = () => {
      isMouseInScreen.current = false;
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  useFrame((state, delta) => {
    const maxRotation = isSmallScreen ? 0.15 : 0.6;
    const springStrength = 0.03;
    const returnStrength = 0.01; // Speed of return to default state

    if (isMouseInScreen.current) {
      rotationRef.current.x +=
        (mouseRef.current.y * maxRotation - rotationRef.current.x) *
        springStrength;
      rotationRef.current.y +=
        (mouseRef.current.x * maxRotation - rotationRef.current.y) *
        springStrength;
    } else {
      // Gradually return to default state
      rotationRef.current.x += (0 - rotationRef.current.x) * returnStrength;
      rotationRef.current.y += (0 - rotationRef.current.y) * returnStrength;
    }

    if (ringRef.current) {
      ringRef.current.rotation.z += delta * 0.1;
      ringRef.current.rotation.x = rotationRef.current.x;
      ringRef.current.rotation.y = rotationRef.current.y;
    }
    if (particlesRef.current) {
      particlesRef.current.rotation.z -= delta * 0.05;
      particlesRef.current.rotation.x = rotationRef.current.x * 0.5;
      particlesRef.current.rotation.y = rotationRef.current.y * 0.5;
    }
  });

  return (
    <group>
      <mesh ref={ringRef}>
        <torusGeometry
          args={[isSmallScreen ? 0.4 : 0.4, isSmallScreen ? 0.2 : 0.2, 16, 100]}
        />
        <meshBasicMaterial color="#fafbfd" />
      </mesh>
      <points ref={particlesRef} geometry={particlesGeometry}>
        <pointsMaterial
          size={isSmallScreen ? 0.004 : 0.005}
          color="#fafbfd"
          transparent
          opacity={0.6}
          sizeAttenuation={true}
        />
      </points>
    </group>
  );
}

function CameraAnimation({ isLoaded, isSmallScreen }) {
  const { camera } = useThree();
  const [vec] = useState(() => new THREE.Vector3());

  useFrame(() => {
    if (isLoaded) {
      vec.set(0, 0, isSmallScreen ? 4 : 3.5); // Adjust camera distance for small screens
      camera.position.lerp(vec, 0.05);
    }
  });

  return null;
}

function Scene({ isLoaded, isSmallScreen }) {
  return (
    <>
      <color attach="background" args={["#000000"]} />
      <CameraAnimation isLoaded={isLoaded} isSmallScreen={isSmallScreen} />
      <NoisyRing isSmallScreen={isSmallScreen} />
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <OrbitControls enableZoom={false} enablePan={false} />
    </>
  );
}

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="app">
      {isLoading && (
        <LoadingScreen
          onLoadingComplete={() => {
            setIsLoading(false);
            setTimeout(() => setIsLoaded(true), 100);
          }}
        />
      )}
      <Canvas camera={{ position: [0, 0, 0.1], fov: isSmallScreen ? 70 : 60 }}>
        <Suspense fallback={null}>
          <Scene isLoaded={isLoaded} isSmallScreen={isSmallScreen} />
        </Suspense>
      </Canvas>
      <TextOverlay /> {/* Add this line to include the TextOverlay */}
    </div>
  );
}

export default App;
