import React, { useState, useEffect } from "react";
import "./LoadingScreen.css";

const LoadingScreen = ({ onLoadingComplete }) => {
  const [progress, setProgress] = useState(0);
  const [showLoader, setShowLoader] = useState(true);
  const duration = 4000; // 4 seconds for loading
  const delayAfterComplete = 2500; // 1 second delay after reaching 100%
  const intervalDuration = 20; // Update every 20ms for smooth animation

  useEffect(() => {
    const startTime = Date.now();

    const interval = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const newProgress = Math.min((elapsedTime / duration) * 100, 100);
      setProgress(Math.floor(newProgress));

      if (elapsedTime >= duration) {
        clearInterval(interval);
        setTimeout(() => {
          setShowLoader(false);
          onLoadingComplete();
        }, delayAfterComplete);
      }
    }, intervalDuration);

    return () => clearInterval(interval);
  }, [onLoadingComplete]);

  if (!showLoader) return null;

  return (
    <div className="loading-screen" style={{ backgroundColor: "#000" }}>
      <div className="loading-percentage">{progress}.</div>
    </div>
  );
};

export default LoadingScreen;
