import React from "react";
import "./TextOverlay.css";
import LinkedInIcon from "./assets/linkedin.svg";

const TextOverlay = () => {
  return (
    <div className="text-overlay">
      <div className="top-bar"></div>
      <div className="bottom-container">
        <div className="bottom-text">
          <p>
            In the boundless realm of creativity, शून्य holds a profound power—a
            blank canvas, an empty space, a world yet to be imagined. If you
            have any ideas that you want to bring from nothingness to life,
            reach out to us.
          </p>
        </div>
        <a
          href="https://www.linkedin.com/company/shooonya"
          target="_blank"
          rel="noopener noreferrer"
          className="linkedin-icon"
        >
          <button className="notify-button">
            <img src={LinkedInIcon} alt="LinkedIn" />
          </button>
        </a>
      </div>
    </div>
  );
};

export default TextOverlay;
